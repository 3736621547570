<template>
  <CardWrapper
    class="pa-4 fill-height"
    min-height="260"
    v-bind="$attrs"
    shadow="hover"
  >
    <VContainer class="pa-0 fill-height">
      <VRow
        align-content="space-between"
        class="fill-height"
        no-gutters
      >
        <VCol cols="12">
          <VRow
            no-gutters
            align="center"
          >
            <VCol>
              <div data-test="group-title">
                {{ title }}
              </div>
            </VCol>
            <VCol cols="auto">
              <MenuWrapper :menu-list="menuItems">
                <template #activator="{attrs,on}">
                  <!--
                 TODO FIX По неизвестной причине если не указать ripple="true" криво работают события, а именно
                 при ripple="false" анимация клика срабатывает даже на самой карточке не смотря на указанный
                 @click.native.prevent, если указать ripple="true", то все работает как ожидается
                  -->
                  <TTBtn
                    small
                    v-bind="attrs"
                    color="rgba(255,255,255,.5)"
                    min-width="28"
                    class="pa-1"
                    :ripple="true"
                    data-test-label="group-button-show-menu"
                    v-on="on"
                    @click.native.prevent
                  >
                    <VIcon>
                      fal fa-ellipsis-h
                    </VIcon>
                  </TTBtn>
                </template>
              </MenuWrapper>
            </VCol>
          </VRow>
          <VRow dense>
            <VCol
              cols="12"
            >
              <div
                class="tt-light-mono-46--text tt-text-body-2"
                data-test="group-description"
              >
                {{ textTruncated }}
              </div>
            </VCol>
          </VRow>
        </VCol>
        <VCol cols="12">
          <VRow
            dense
          >
            <VCol cols="auto">
              <VChip data-test="group-card-chips-users">
                <VIcon
                  left
                  color="tt-light-mono-64"
                >
                  fas fa-user-circle
                </VIcon>
                <span class="tt-light-mono-64--text tt-text-caption">
                  18 человек
                </span>
              </VChip>
            </VCol>
            <VCol cols="auto">
              <VChip data-test="group-card-chips-track">
                <VIcon
                  left
                  color="tt-light-mono-64"
                >
                  fas fa-route
                </VIcon>
                <span class="tt-light-mono-64--text tt-text-caption">
                  1 трек
                </span>
              </VChip>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VContainer>
  </CardWrapper>
</template>
<script>
import CardWrapper from '@/components/shared/CardWrapper.vue';
import MenuWrapper from '@/components/shared/MenuWrapper.vue';
import { stringTruncate } from '@/utils/stringHelpers';

export default {
  name: 'GroupCard',
  components: {
    MenuWrapper,
    CardWrapper,
  },
  props: {
    imageSrc: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },

    menuItems: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    textTruncated() {
      return stringTruncate(this.text, 140);
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
