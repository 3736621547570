<template>
  <VContainer>
    <VRow>
      <VCol>
        <Transition
          name="fade"
          mode="out-in"
        >
          <h1
            class="tt-text-headline-1"
            data-test="groups-title"
          >
            Группы
          </h1>
        </Transition>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="6">
        <TTTextField
          v-model="searchValue"
          clear-icon="fas fa-times-circle"
          append-icon="fal fa-search"
          large
          label="Поиск"
          placeholder="Название группы"
          clearable
          data-test-label="groups-search"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VDataIterator
          :items="groups"
          :search="searchValue"
          hide-default-footer
          :custom-filter="customFilter"
          :items-per-page="-1"
        >
          <template #default="props">
            <VRow>
              <VCol
                v-for="(item,index) in props.items"
                :key="item.title"
                cols="3"
              >
                <GroupCard
                  link
                  data-test="group-card"
                  :image-src="item.imageSrc"
                  :title="item.title"
                  :text="item.text"
                  :to="{name : Names.R_GROUP,params : {id : index}}"
                  :menu-items="generateTrackCardMenuList(index,item.title)"
                />
              </VCol>
            </VRow>
          </template>
          <template #no-results>
            <NoResult
              data-test="no-result-groups"
              title="Такой группы нет"
              description=" Проверьте написание поискового запроса или используйте другие ключевые слова"
            />
          </template>
          <template #no-data>
            <VRow>
              <VCol>
                <EmptySection
                  data-test="empty-section"
                  title="Создайте группу"
                  text="Здесь появятся группы, в которые можно добавлять сотрудников."
                />
              </VCol>
            </VRow>
          </template>
        </VDataIterator>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import GroupCard from '@/components/cards/GroupCard.vue';
import EmptySection from '@/components/shared/EmptySection.vue';
import NoResult from '@/components/shared/NoResult.vue';

export default {
  name: 'Groups',
  components: {
    NoResult,
    EmptySection,
    GroupCard,
  },
  inject: ['Names'],
  data() {
    return {
      searchValue: '',
      // groups: [],
      groups: [...Array(5)]
        .map((_, index) => (
          {
            id: index,
            title: `Card Title ${index}`,
            // eslint-disable-next-line max-len,vue/max-len
            text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam, aliquid eligendi esse ex fugit illo impedit ipsa maiores nam nisi nostrum perspiciatis, reprehenderit, sed voluptate. Corporis illo ipsa reiciendis.\n',
          })),
    };
  },
  mounted() {
    this.$root.$on('click:group-create', this.showCreateForm);
  },
  beforeDestroy() {
    this.$root.$off('click:group-create', this.showCreateForm);
  },
  methods: {
    showCreateForm() {
      this.$router.push({ name: this.Names.R_GROUP_CREATE });
    },
    customFilter(items, search) {
      if (!search) return items;
      return items.filter((item) => item.title.toUpperCase()
        .includes(search.toUpperCase()));
    },
    deleteTrack(id) {
      const deleteIndex = this.groups.findIndex((item) => item.id === id);
      this.groups.splice(deleteIndex, 1);
    },
    generateTrackCardMenuList(id, title) {
      const confirmationDeleteAction = async () => {
        this.deleteTrack(id);
      };
      const editAction = async () => {
        this.$router.push({ name: this.Names.R_GROUP_MANAGEMENT, params: { id } });
      };
      const menuDeleteAction = () => {
        this.$root.$emit('showConfirmation', {
          title: 'Удалить группу?',
          description: `Сотрудники из группы «${title}» потеряют доступ к назначенным трекам`,
          confirmAction: confirmationDeleteAction,
          confirmButtonColor: 'tt-danger',
          confirmButtonText: 'Удалить',
        });
      };
      return [
        {
          icon: 'fal fa-pen',
          color: '',
          text: 'Редактировать',
          dataTest: 'group-button-open-update-form',
          action: editAction,
        }, {
          icon: 'fal fa-trash-alt',
          color: 'tt-light-red',
          text: 'Удалить',
          dataTest: 'group-button-open-delete-form',
          action: menuDeleteAction,
        },
      ];
    },
  },
};
</script>

<style scoped>

</style>
